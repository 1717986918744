import * as React from "react"
import styled from "styled-components"
import InputWrapper from "./InputWrapper"
import { motion, useAnimation } from "framer-motion"
import { useEffect, useState } from "react"
import {toast} from "react-toastify"

const StyledForm = styled(motion.form)`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 50%;
  textarea {
    width: 100%;
  }
`

const Submit = styled(motion.input)`
  border: none;
  background-color: #dedc00;
  color: white;
  padding: 0.5em 1.5rem;
  width: fit-content;
  align-self: flex-end;
  cursor: pointer;
`





const buttonVariant = {
  hidden: { opacity: 0, x: -200 },
  show: { opacity: 1, x: 0 },
  tap: { scale: 0.95 },
  hover: { scale: 1.05 }
}

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      when: "beforeChildren",
      delay: 0.5,
    },
  },
}



const Form = () => {

const successToast = () => toast.success('👍 Votre message a bien été envoyé.', {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  });

  const errorToast = () => toast.error('👎 Il y a eu un problème.', {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });




  const [resetCount, setResetCount] = useState(0);
  const formControl = useAnimation();

  useEffect(() => {
    formControl.start("show")
  }, [formControl])

  const formSubmissionHandler = e => {
    e.preventDefault()

    const formElement = e.target,
      { action, method } = formElement,
      body = new FormData(formElement)

    fetch(action, {
      method,
      body,
    })
      .then(response => response.json())
      .then(async (response) => {
        if (response.status !== "mail_sent") {
          successToast();
        } else {
          errorToast();
        }
        await formControl.start("hidden");
        setTimeout(async () => {
          document.getElementById("contact-form").reset();
          await formControl.start("show");
          await setResetCount(resetCount + 10);
        }, 1000);

      })
  }
  let fieldvariant = {

    show: { opacity: 1, y: 0, x: 0 }
  }
  const isMobile = typeof window !== `undefined` ? window.innerWidth < 481 : false;
  if (!isMobile) {
    fieldvariant.hidden = { opacity: 0, y: 0 }
  } else {
    fieldvariant.hidden = { opacity: 0, x: "-120%" }
  }
  return (<>
    <StyledForm
      id={"contact-form"}
      variants={container}
      initial={"show"}
      animate={formControl}
      onSubmit={e => formSubmissionHandler(e)}
      action={
        "http://wp.mariannealliot.com/wp-json/contact-form-7/v1/contact-forms/105/feedback"
      }
      method={"post"}
      key={`form_${resetCount}`}
    >
      <InputWrapper
        variants={fieldvariant}
        name={"your-name"}
        initial={"show"}
        animate={formControl}
        label={"name"}
        required
        type={"text"}

      />
      <InputWrapper
        variants={fieldvariant}
        name={"your-email"}
        initial={"show"}
        label={"email"}
        required
        type={"email"}

      />
      <InputWrapper
        variants={fieldvariant}
        name={"your-subject"}
        initial={"show"}
        label={"subject"}
        required
        type={"text"}

      />
      <InputWrapper
        variants={fieldvariant}
        name={"your-message"}
        initial={"show"}
        label={"message"}
        required
        textarea

      />
      <Submit
        variants={buttonVariant}
        type={"submit"}
        value={"send"}
        whileHover={"hover"}
        whileTap={"tap"}
      />
    </StyledForm>

  </>)
}

export default Form
